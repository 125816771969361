var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "employees__wrapper" }, [
    _c("div", { staticClass: "employees__header" }, [
      _c("div", { staticClass: "employees__header-title" }, [
        _vm._v("List of Employees")
      ]),
      _c(
        "button",
        {
          staticClass: "button button--fill button--rounded",
          on: {
            click: function($event) {
              _vm.showForm = true
            }
          }
        },
        [_vm._v("Add New Employee")]
      )
    ]),
    _vm.showForm
      ? _c("div", { staticClass: "employees__form" }, [
          _c(
            "label",
            {
              staticClass: "field__label",
              class: { "field__label--filled": _vm.newEmployee }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newEmployee,
                    expression: "newEmployee"
                  }
                ],
                staticClass: "field__input",
                attrs: { type: "text", placeholder: "New Employee" },
                domProps: { value: _vm.newEmployee },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.newEmployee = $event.target.value
                  }
                }
              })
            ]
          ),
          _c(
            "button",
            {
              staticClass: "button button--fill",
              on: { click: _vm.addEmployee }
            },
            [_vm._v("Add Employee")]
          )
        ])
      : _vm._e(),
    _c("div", { staticClass: "employees__main" }, [
      _c(
        "div",
        { staticClass: "employees__list" },
        _vm._l(_vm.info.employeeNames, function(name, i) {
          return _c(
            "div",
            { key: i, staticClass: "employees__item" },
            [
              _c("div", { staticClass: "employees__item-name" }, [
                _vm._v(_vm._s(name))
              ]),
              _c(
                "el-dropdown",
                { attrs: { trigger: "click" }, on: { command: _vm.action } },
                [
                  _c("i", { staticClass: "ri-more-fill" }),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "el-popper-custom el-popper--actions",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown"
                    },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass: "item--remove",
                          attrs: {
                            command: { i: i, name: name, act: "remove" }
                          }
                        },
                        [
                          _c("i", { staticClass: "ri-delete-bin-7-fill" }),
                          _vm._v(" Remove ")
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }