import { render, staticRenderFns } from "./index.vue?vue&type=template&id=06cfbed4&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=06cfbed4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06cfbed4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/workspace/sts-app_develop/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('06cfbed4')) {
      api.createRecord('06cfbed4', component.options)
    } else {
      api.reload('06cfbed4', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=06cfbed4&scoped=true&", function () {
      api.rerender('06cfbed4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Enterprise/Account/Payment/NewCard/index.vue"
export default component.exports