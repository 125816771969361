<template>
  <div class="pass__wrapper">
    <form class="invite__form" @submit.prevent="register">
      <label
        class="field__label"
        :class="{
          'field__label--filled': $v.oldPassword.required && $v.oldPassword.minLength,
          'field__label--invalid':
            ($v.oldPassword.$dirty && !$v.oldPassword.required) || ($v.oldPassword.$dirty && !$v.oldPassword.minLength)
        }"
      >
        <div class="field__title field__title">Old Password</div>
        <input v-model.trim="$v.oldPassword.$model" class="field__input" type="text" placeholder="Old Password" />
      </label>
      <label
        class="field__label"
        :class="{
          'field__label--filled': $v.password.required && $v.password.minLength,
          'field__label--invalid':
            ($v.password.$dirty && !$v.password.required) || ($v.password.$dirty && !$v.password.minLength)
        }"
      >
        <div class="field__title field__title">Password</div>
        <input v-model.trim="$v.password.$model" class="field__input" type="text" placeholder="Password" />
      </label>
      <label
        class="field__label"
        :class="{
          'field__label--filled': $v.passwordConfirmation.sameAsPassword && $v.password.$dirty,
          'field__label--invalid': !$v.passwordConfirmation.sameAsPassword && $v.password.$dirty
        }"
      >
        <div class="field__title field__title">Confirm Password</div>
        <input
          v-model.trim="$v.passwordConfirmation.$model"
          class="field__input"
          type="text"
          placeholder="Confirm Password"
        />
      </label>
      <button type="submit" class="button button--fill">
        Change password
      </button>
    </form>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import {required, minLength, sameAs} from 'vuelidate/lib/validators'

export default {
  name: 'PasswordCustomer',
  data: () => ({
    oldPassword: null,
    password: null,
    passwordConfirmation: null
  }),
  methods: {
    ...mapActions({
      update: 'client/updatePassword',
      logout: 'logout'
    }),
    async register() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const passObject = {
        currentPassword: this.oldPassword,
        password: this.password,
        passwordConfirmation: this.passwordConfirmation
      }
      const passReq = await this.update(passObject)
      if (passReq.data.result) {
        this.logout()
        this.$router.push('/login')
      }
    }
  },

  validations: {
    oldPassword: {required, minLength: minLength(8)},
    password: {required, minLength: minLength(8)},
    passwordConfirmation: {sameAsPassword: sameAs('password')}
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
