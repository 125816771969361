var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-wrapper" }, [
    _c("div", { staticClass: "page-title" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "page-title-inner" }, [
          _c("span", { staticClass: "page-title--user" }, [
            _vm._v(
              _vm._s(_vm.info.firstName) +
                " " +
                _vm._s(_vm.info.middleInitial) +
                " " +
                _vm._s(_vm.info.lastName)
            )
          ]),
          _c("span", [_vm._v(" / ")]),
          _c("span", [_vm._v("Profile Edit")]),
          _c("span", [_vm._v(" / ")]),
          _c("span", [_vm._v(_vm._s(_vm.screenType.name))])
        ])
      ])
    ]),
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "page-inner" },
        [
          _c("div", { staticClass: "page-nav" }, [
            _c(
              "div",
              {
                staticClass: "page-nav__item",
                class: { "page-nav__item--active": _vm.screenType.id === 1 },
                on: {
                  click: function($event) {
                    return _vm.changeScreen(1)
                  }
                }
              },
              [_vm._v(" General ")]
            ),
            _c(
              "div",
              {
                staticClass: "page-nav__item",
                class: { "page-nav__item--active": _vm.screenType.id === 2 },
                on: {
                  click: function($event) {
                    return _vm.changeScreen(2)
                  }
                }
              },
              [_vm._v(" Payment Info ")]
            ),
            _c(
              "div",
              {
                staticClass: "page-nav__item",
                class: { "page-nav__item--active": _vm.screenType.id === 3 },
                on: {
                  click: function($event) {
                    return _vm.changeScreen(3)
                  }
                }
              },
              [_vm._v(" Password ")]
            ),
            _c(
              "div",
              {
                staticClass: "page-nav__item",
                class: { "page-nav__item--active": _vm.screenType.id === 4 },
                on: {
                  click: function($event) {
                    return _vm.changeScreen(4)
                  }
                }
              },
              [_vm._v(" Employee's ")]
            )
          ]),
          _c("Multiselect", {
            staticClass: "customer-type-change",
            attrs: {
              options: _vm.screenTypes,
              "track-by": "id",
              label: "name",
              "show-labels": false,
              "allow-empty": false,
              searchable: false
            },
            model: {
              value: _vm.screenType,
              callback: function($$v) {
                _vm.screenType = $$v
              },
              expression: "screenType"
            }
          }),
          _c(
            "div",
            { staticClass: "page-main" },
            [
              _vm.screenType.id === 1 ? _c("Edit") : _vm._e(),
              _vm.screenType.id === 2 ? _c("Payment") : _vm._e(),
              _vm.screenType.id === 3 ? _c("Password") : _vm._e(),
              _vm.screenType.id === 4 ? _c("Employees") : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }