<template>
  <div class="card">
    <div class="card-brand">
      <i v-if="card.brand === 'visa'" class="ri-visa-line"></i>
      <i v-else-if="card.brand === 'mastercard'" class="ri-mastercard-line"></i>
      <i v-else class="ri-bank-card-line"></i>
    </div>
    <div class="card-title">
      <div class="card-type">
        <span v-if="card.cardName">{{ card.cardName }} / </span>{{ card.cardType }}
      </div>
      <div class="card-number">**** **** **** {{ card.last4 }}</div>
    </div>
    <div class="card-actions" @click.stop>
      <el-dropdown trigger="click" @command="action">
        <i class="ri-more-fill"></i>
        <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
          <el-dropdown-item class="item--remove" command="Delete">
            <i class="ri-delete-bin-7-fill"></i> Delete
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    card: {
      type: Object,
      default: null
    }
  },
  methods: {
    async action(act) {
      if (act === 'Delete') {
        this.$emit('load', true)
        await this.$store.dispatch('card/delete', this.card.paymentID)
        this.$emit('load', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
