<template>
  <div class="edit__wrapper">
    <div class="edit__part">
      <div class="edit__part-title">Basic Data</div>
      <div class="edit__part--thirds">
        <label class="field__label" :class="{'field__label--filled': $v.firstName.$model && $v.firstName.$dirty}">
          <div class="field__title">First Name</div>
          <input v-model="$v.firstName.$model" class="field__input" type="text" placeholder="First Name" />
        </label>
        <label
          class="field__label field__label--required"
          :class="{
            'field__label--filled': $v.middleInitial.$model && $v.middleInitial.$dirty,
            'field__label--invalid': $v.middleInitial.$dirty && !$v.middleInitial.required
          }"
        >
          <div class="field__title">Middle Initial</div>
          <input
            v-model="$v.middleInitial.$model"
            v-mask="'AA'"
            class="field__input field__input--uppercase"
            type="text"
            placeholder="MI"
          />
        </label>
        <label
          class="field__label field__label-last-name"
          :class="{'field__label--filled': $v.lastName.$model && $v.lastName.$dirty}"
        >
          <div class="field__title">Last Name</div>
          <input v-model="$v.lastName.$model" class="field__input" type="text" placeholder="Last Name" />
        </label>
      </div>
      <label
        class="field__label field__label--required"
        :class="{
          'field__label--filled': $v.nickName.$model && $v.nickName.$dirty,
          'field__label--invalid': $v.nickName.$dirty && !$v.nickName.required
        }"
      >
        <div class="field__title">Nick Name</div>
        <input v-model="$v.nickName.$model" class="field__input" type="text" placeholder="Nick Name" />
      </label>
      <label class="field__label" :class="{'field__label--filled': $v.fathersName.$model && $v.fathersName.$dirty}">
        <div class="field__title">Father’s Name</div>
        <input v-model="$v.fathersName.$model" class="field__input" type="text" placeholder="Father’s Name" />
      </label>
      <label class="field__label field__label--readonly">
        <div class="field__title">
          Main Cell Phone
          <el-popover
            placement="bottom-start"
            width="290"
            trigger="hover"
            content="To change this number please contact Brett at brett@tidallock.io"
          >
            <i slot="reference" class="ri-information-fill"></i>
          </el-popover>
        </div>
        <input :value="$v.cellPhone.$model" class="field__input" type="text" placeholder="Main Cell Phone" readonly />
      </label>
      <label
        class="field__label"
        :class="{'field__label--filled': $v.contactEmail.$model && $v.contactEmail.$dirty && $v.contactEmail.email}"
      >
        <div class="field__title">Contact E-mail</div>
        <input v-model="$v.contactEmail.$model" class="field__input" type="text" placeholder="Contact E-mail" />
      </label>
    </div>
    <div class="edit__part">
      <div class="edit__part-title">Business Information</div>
      <label
        class="field__label field__label--required field__label--full"
        :class="{
          'field__label--filled': $v.businessName.$model && $v.businessName.$dirty,
          'field__label--invalid': $v.businessName.$dirty && !$v.businessName.required
        }"
      >
        <div class="field__title">Business Name</div>
        <input v-model="$v.businessName.$model" class="field__input" type="text" placeholder="Business Name" />
      </label>
      <label
        class="field__label"
        :class="{
          'field__label--filled': $v.businessPhone.$model && $v.businessPhone.$dirty && $v.businessPhone.phoneReg,
          'field__label--invalid': $v.businessPhone.$dirty && !$v.businessPhone.phoneReg
        }"
      >
        <div class="field__title">Business Phone</div>
        <input
          v-model="$v.businessPhone.$model"
          v-mask="'### ### ####'"
          class="field__input"
          type="text"
          placeholder="Business Phone"
        />
      </label>
      <label
        class="field__label"
        :class="{
          'field__label--filled': $v.accountingEmail.$model && $v.accountingEmail.$dirty && $v.accountingEmail.email,
          'field__label--invalid': $v.accountingEmail.$dirty && !$v.accountingEmail.email
        }"
      >
        <div class="field__title">Accounting E-mail</div>
        <input v-model="$v.accountingEmail.$model" class="field__input" type="text" placeholder="Accounting E-mail" />
      </label>
    </div>
    <div class="edit__part">
      <div class="edit__part-title">Address Information</div>
      <label class="field__label" :class="{'field__label--filled': $v.address.$model && $v.address.$dirty}">
        <div class="field__title">Address</div>
        <input v-model="$v.address.$model" class="field__input" type="text" placeholder="Address" />
      </label>
      <label class="field__label" :class="{'field__label--filled': $v.city.$model && $v.city.$dirty}">
        <div class="field__title">City</div>
        <input v-model="$v.city.$model" class="field__input" type="text" placeholder="City" />
      </label>
      <label class="field__label" :class="{'field__label--filled': $v.province.$model && $v.province.$dirty}">
        <div class="field__title">Province</div>
        <input v-model="$v.province.$model" class="field__input" type="text" placeholder="Province" />
      </label>
      <label
        class="field__label"
        :class="{
          'field__label--filled': $v.postalCode.$model && $v.postalCode.$dirty && $v.postalCode.minLength,
          'field__label--invalid': $v.postalCode.$dirty && !$v.postalCode.minLength
        }"
      >
        <div class="field__title">Postal Code</div>
        <input v-model="$v.postalCode.$model" class="field__input" type="text" placeholder="Postal Code" />
      </label>
    </div>
    <div class="edit__part">
      <div class="edit__part-title">Personal Information</div>
      <label
        class="field__label"
        :class="{
          'field__label--filled': $v.homePhone.$model && $v.homePhone.$dirty && $v.homePhone.phoneReg,
          'field__label--invalid': $v.homePhone.$dirty && !$v.homePhone.phoneReg
        }"
      >
        <div class="field__title">Home Phone</div>
        <input
          v-model="$v.homePhone.$model"
          v-mask="'### ### ####'"
          class="field__input"
          type="text"
          placeholder="Home Phone"
        />
      </label>
    </div>
    <button class="button button--fill" @click="save">Save edits</button>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import {required, email, minLength, helpers} from 'vuelidate/lib/validators'
const phoneReg = helpers.regex('phoneReg', /^[2-9]\d{2} \d{3} \d{4}$/)
import _ from 'lodash'

export default {
  name: 'ClientInfo',
  data: () => ({
    isLoading: false,

    firstName: null,
    middleInitial: null,
    lastName: null,
    nickName: null,
    fathersName: null,
    cellPhone: null,
    contactEmail: null,

    businessName: null,
    businessPhone: null,
    accountingEmail: null,

    province: null,
    city: null,
    address: null,
    postalCode: null,

    homePhone: null
  }),
  computed: {
    ...mapState({
      info: s => s.client.info
    })
  },
  watch: {
    info(info) {
      _.assign(this.$data, info)
    }
  },
  created() {
    _.assign(this.$data, this.info)
  },
  methods: {
    ...mapActions({
      fetch: 'client/fetch',
      update: 'client/update'
    }),
    async save() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const UserData = {
        firstName: this.firstName,
        middleInitial: this.middleInitial,
        lastName: this.lastName,
        nickName: this.nickName,
        fathersName: this.fathersName,
        contactEmail: this.contactEmail,

        businessName: this.businessName,
        businessPhone: this.businessPhone,
        accountingEmail: this.accountingEmail,

        province: this.province,
        city: this.city,
        address: this.address,
        postalCode: this.postalCode,

        homePhone: this.homePhone
      }
      await this.update(UserData)
      await this.fetch()
      this.$message({
        showClose: true,
        iconClass: 'ri-checkbox-circle-fill',
        customClass: 'el-message-custom--success',
        message: 'Profile info has been updated!'
      })
    }
  },
  validations: {
    firstName: {},
    middleInitial: {required},
    lastName: {},
    nickName: {required},
    fathersName: {},
    cellPhone: {required},
    contactEmail: {email},

    businessName: {required},
    accountingEmail: {email},
    businessPhone: {phoneReg},

    address: {},
    city: {},
    province: {},
    postalCode: {minLength: minLength(5)},

    homePhone: {phoneReg}
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
