var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "payment__wrapper" }, [
    _c("div", { staticClass: "payment__title" }, [_vm._v("Your cards")]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading"
          }
        ],
        staticClass: "loader__wrapper"
      },
      _vm._l(3, function(i) {
        return _c("Skeleton", { key: i, attrs: { height: "63px" } })
      }),
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading,
            expression: "!isLoading"
          }
        ]
      },
      _vm._l(_vm.cards, function(card) {
        return _c("Card", {
          key: card.id,
          attrs: { card: card },
          on: { load: _vm.load }
        })
      }),
      1
    ),
    _c(
      "button",
      { staticClass: "button payment__button", on: { click: _vm.addCard } },
      [
        _c("i", { staticClass: "ri-add-circle-fill" }),
        _c("span", { staticClass: "payment__button-text" }, [
          _vm._v("Add New Card")
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }