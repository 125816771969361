<template>
  <div class="employees__wrapper">
    <div class="employees__header">
      <div class="employees__header-title">List of Employees</div>
      <button class="button button--fill button--rounded" @click="showForm = true">Add New Employee</button>
    </div>
    <div v-if="showForm" class="employees__form">
      <label class="field__label" :class="{'field__label--filled': newEmployee}">
        <input v-model="newEmployee" class="field__input" type="text" placeholder="New Employee" />
      </label>
      <button class="button button--fill" @click="addEmployee">Add Employee</button>
    </div>
    <div class="employees__main">
      <div class="employees__list">
        <div v-for="(name, i) of info.employeeNames" :key="i" class="employees__item">
          <div class="employees__item-name">{{ name }}</div>
          <el-dropdown trigger="click" @command="action">
            <i class="ri-more-fill"></i>
            <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
              <el-dropdown-item class="item--remove" :command="{i, name, act: 'remove'}">
                <i class="ri-delete-bin-7-fill"></i> Remove
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import _ from 'lodash'

export default {
  name: 'EmployeesCustomer',
  data() {
    return {
      newEmployee: null,
      showForm: false
    }
  },
  computed: {
    ...mapState({
      info: s => s.client.info
    })
  },
  watch: {
    info(info) {
      _.assign(this.$data, info)
    }
  },
  created() {
    _.assign(this.$data, this.info)
  },
  methods: {
    ...mapActions({
      fetch: 'client/fetch',
      update: 'client/update'
    }),
    async action(e) {
      if (e.act === 'remove') {
        this.info.employeeNames.splice(e.i, 1)
        await this.save()
      }
    },
    async addEmployee() {
      if (!this.newEmployee) return
      try {
        this.info.employeeNames.push(this.newEmployee.toString().trim())
        await this.save()
      } finally {
        this.newEmployee = null
      }
    },
    async save() {
      const UserData = {
        firstName: this.info.firstName,
        middleInitial: this.info.middleInitial,
        lastName: this.info.lastName,
        nickName: this.info.nickName,
        fathersName: this.info.fathersName,
        contactEmail: this.info.contactEmail,

        businessName: this.info.businessName,
        businessPhone: this.info.businessPhone,
        accountingEmail: this.info.accountingEmail,

        province: this.info.province,
        city: this.info.city,
        address: this.info.address,
        postalCode: this.info.postalCode,

        homePhone: this.info.homePhone,

        accountingName: this.info.accountingName,
        accountingPhone: this.info.accountingPhone,
        isEnterprise: true,
        employeeNames: this.info.employeeNames
      }
      await this.update(UserData)
      await this.fetch()
      this.$message({
        showClose: true,
        iconClass: 'ri-checkbox-circle-fill',
        customClass: 'el-message-custom--success',
        message: 'Profile info has been updated!'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
