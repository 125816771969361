<template>
  <div class="page-wrapper">
    <div class="page-title">
      <div class="container">
        <div class="page-title-inner">
          <span class="page-title--user">{{ info.firstName }} {{ info.middleInitial }} {{ info.lastName }}</span>
          <span> / </span>
          <span>Profile Edit</span>
          <span> / </span>
          <span>{{ screenType.name }}</span>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="page-inner">
        <div class="page-nav">
          <div class="page-nav__item" :class="{'page-nav__item--active': screenType.id === 1}" @click="changeScreen(1)">
            General
          </div>
          <div class="page-nav__item" :class="{'page-nav__item--active': screenType.id === 2}" @click="changeScreen(2)">
            Payment Info
          </div>
          <div class="page-nav__item" :class="{'page-nav__item--active': screenType.id === 3}" @click="changeScreen(3)">
            Password
          </div>
          <div class="page-nav__item" :class="{'page-nav__item--active': screenType.id === 4}" @click="changeScreen(4)">
            Employee's
          </div>
          <!-- <div class="page-nav__item" :class="{'page-nav__item--active': screenType.id === 5}" @click="changeScreen(5)">
            Logo
          </div> -->
        </div>
        <Multiselect
          v-model="screenType"
          :options="screenTypes"
          track-by="id"
          label="name"
          class="customer-type-change"
          :show-labels="false"
          :allow-empty="false"
          :searchable="false"
        >
        </Multiselect>
        <div class="page-main">
          <Edit v-if="screenType.id === 1" />
          <Payment v-if="screenType.id === 2" />
          <Password v-if="screenType.id === 3" />
          <Employees v-if="screenType.id === 4" />
          <!-- <Logo v-if="screenType.id === 5" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Edit from './Edit'
import Payment from './Payment'
import Password from './Password'
import Employees from './Employees'
// import Logo from './Logo'
import Multiselect from 'vue-multiselect'
import {mapState} from 'vuex'
export default {
  name: 'ClientPage',
  components: {Edit, Payment, Password, Employees, Multiselect},
  data: () => ({
    screenType: {name: 'General', id: 1},
    screenTypes: [
      {name: 'General', id: 1},
      {name: 'Payment Info', id: 2},
      {name: 'Password', id: 3},
      {name: 'Employees', id: 4},
      // {name: 'Logo', id: 5}
    ]
  }),
  computed: {
    ...mapState({
      info: s => s.client.info
    })
  },
  methods: {
    changeScreen(id) {
      this.screenType = this.screenTypes.find(t => t.id === id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
