<template>
  <div class="payment__wrapper">
    <div class="payment__title">Your cards</div>
    <div v-show="isLoading" class="loader__wrapper">
      <Skeleton v-for="i of 3" :key="i" :height="'63px'" />
    </div>
    <div v-show="!isLoading">
      <Card v-for="card of cards" :key="card.id" :card="card" @load="load" />
    </div>
    <button class="button payment__button" @click="addCard">
      <i class="ri-add-circle-fill"></i>
      <span class="payment__button-text">Add New Card</span>
    </button>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import Card from './Card'
import NewCard from './NewCard'
import Skeleton from '@/components/Skeleton'

export default {
  name: 'Payment',
  components: {Card, Skeleton},
  data: () => ({
    isLoading: true
  }),
  computed: {
    ...mapState({
      cards: s => s.card.cards
    })
  },
  async created() {
    await this.fetch()
    this.isLoading = false
  },
  methods: {
    ...mapActions({
      fetch: 'card/fetch'
    }),
    addCard() {
      this.$modal.display(
        NewCard,
        {},
        {
          name: 'NewCardModal',
          transition: 'none',
          overlayTransition: 'none',
          height: 'auto',
          width: 360,
          classes: 'modal modal-payment-card'
        }
      )
    },
    load(loader) {
      this.isLoading = loader
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
