var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal__wrapper" },
    [
      _vm.isLoading ? _c("Loader") : _vm._e(),
      _c(
        "button",
        {
          staticClass: "icon-button icon-button--round",
          on: {
            click: function($event) {
              return _vm.$modal.hide("NewCardModal")
            }
          }
        },
        [_c("i", { staticClass: "ri-close-circle-fill" })]
      ),
      _c("div", { staticClass: "modal__card-title" }, [
        _vm._v(" New payment option ")
      ]),
      _c("div", { staticClass: "modalcard-subtitle" }, [
        _vm._v(" You can save as many cards as you’d like. ")
      ]),
      _c("div", { staticClass: "modal__card-title" }, [
        _vm._v(" Is this business or personal card? ")
      ]),
      _c("div", { staticClass: "modal__card-choice" }, [
        _c("label", { staticClass: "radio__label" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.type,
                expression: "type"
              }
            ],
            staticClass: "radio__input",
            attrs: { type: "radio", value: "business" },
            domProps: { checked: _vm._q(_vm.type, "business") },
            on: {
              change: function($event) {
                _vm.type = "business"
              }
            }
          }),
          _c("span", { staticClass: "radio__view" }),
          _c("span", { staticClass: "radio__text" }, [_vm._v("Business card")])
        ]),
        _c("label", { staticClass: "radio__label" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.type,
                expression: "type"
              }
            ],
            staticClass: "radio__input",
            attrs: { type: "radio", value: "personal" },
            domProps: { checked: _vm._q(_vm.type, "personal") },
            on: {
              change: function($event) {
                _vm.type = "personal"
              }
            }
          }),
          _c("span", { staticClass: "radio__view" }),
          _c("span", { staticClass: "radio__text" }, [_vm._v("Personal card")])
        ])
      ]),
      _c("form", { staticClass: "modal__form" }, [
        _vm._m(0),
        _c(
          "label",
          { staticClass: "modal__card-label modal__card-label-line" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.cardName,
                  expression: "cardName"
                }
              ],
              staticClass: "modal__card-input",
              attrs: { type: "text", placeholder: "Card Name" },
              domProps: { value: _vm.cardName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.cardName = $event.target.value
                }
              }
            })
          ]
        ),
        _vm._m(1),
        _c(
          "label",
          {
            staticClass: "modal__card-label is-hide",
            class: { "is-show": _vm.isCvc }
          },
          [
            _c("div", {
              staticClass: "field",
              attrs: { id: "card-cvc-element" }
            })
          ]
        ),
        _c(
          "div",
          {
            staticClass: "modal__button--cvc",
            class: { "is-hide": _vm.isCvc },
            attrs: { id: "addCvc" }
          },
          [_vm._v("Add CVC")]
        ),
        _vm._m(2)
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "modal__card-label modal__card-label-line" },
      [
        _c("div", {
          staticClass: "field",
          attrs: { id: "card-number-element" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "modal__card-label" }, [
      _c("div", { staticClass: "field", attrs: { id: "card-expiry-element" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "modal__button", attrs: { id: "sendBtn" } },
      [
        _c("span", { staticClass: "modal__button-text" }, [
          _vm._v("Save this method")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }